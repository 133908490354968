export default function() {
  document.getElementById('sidebarToggle').addEventListener('click', function() {
    document.body.classList.toggle('sidebar-toggled')
    document.getElementById('accordionSidebar').classList.toggle('toggled')
  })

  document.getElementById('sidebarToggleTop').addEventListener('click', function() {
    document.body.classList.toggle('sidebar-toggled')
    document.getElementById('accordionSidebar').classList.toggle('toggled')
  })
}