<template>
  <div>
    <error-403 v-if="status == 403" />
    <error-404 v-if="status == 404" />
    <error-500 v-if="status == 500" />
  </div>
</template>

<script>
import Error403 from './403.vue'
import Error404 from './404.vue'
import Error500 from './500.vue'

export default {
  components: {
    Error404, Error403, Error500
  },

  computed: {
    status() {
      return this.$store.getters.getError
    }
  }
}
</script>