<template>
  <div id="wrapper">
    <!-- Sidebar -->
    <app-sidebar />
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <app-header />
        <!-- End of Topbar -->

        <!-- Begin Page Content -->
        <div class="container-fluid">
          <!-- Page Heading -->
          <template v-if="! $store.getters.getError">
            <router-view />
          </template>
          <template v-if="$store.getters.getError">
            <admin-error />
          </template>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- End of Main Content -->
    </div>
    <!-- End of Content Wrapper -->
  </div>
</template>

<script>
import "@/assets/admin/main";
import AppHeader from './admin/components/AppHeader.vue'
import AppSidebar from './admin/components/AppSidebar.vue'
import AdminError from './admin/errors/Index.vue'

export default {
  components: {
    AppHeader, AppSidebar, AdminError
  }
};
</script>