<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <router-link
      class="sidebar-brand d-flex align-items-center justify-content-center"
      :to="{ name: 'admin.dashboard' }"
    >
      <div class="sidebar-brand-icon rotate-n-15">
        r
      </div>
      <div class="sidebar-brand-text mx-3">realhub</div>
    </router-link>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.dashboard' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Dashboard</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.properties.index' }">
        <i class="fa fa-fw fa-home"></i>
        <span>Properties</span></router-link
      >
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.property-feedback' }">
        <i class="fa fa-fw fa-home"></i>
        <span>Property Feedback</span></router-link
      >
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div class="sidebar-heading">Management</div>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.users.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Users</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.agencies.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Agencies</span></router-link
      >
    </li>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        data-toggle="collapse"
        data-target="#collapseTwo"
        aria-expanded="true"
        aria-controls="collapseTwo"
      >
        <i class="fa fa-fw fa-cog"></i>
        <span>Listing Features</span>
      </a>
      <div
        id="collapseTwo"
        class="collapse"
        aria-labelledby="headingTwo"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Listing Options:</h6>
          <router-link class="collapse-item" :to="{ name: 'admin.regions.index' }">Regions</router-link>
          <router-link class="collapse-item" :to="{ name: 'admin.categories.index' }">Categories</router-link>
          <router-link class="collapse-item" :to="{ name: 'admin.amenities.index' }">Amenities</router-link>
          <router-link class="collapse-item" :to="{ name: 'admin.details.index' }">Details</router-link>
        </div>
      </div>
    </li>

    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-bs-toggle="collapse"
        data-bs-target="#content-management"
        data-toggle="collapse"
        data-target="#content-management"
        aria-expanded="true"
        aria-controls="content-management"
      >
        <i class="fa fa-fw fa-cog"></i>
        <span>Content Management</span>
      </a>
      <div
        id="content-management"
        class="collapse"
        aria-labelledby="headingTwo"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <router-link class="collapse-item" :to="{ name: 'admin.content.about' }">About Us</router-link>
          <router-link class="collapse-item" :to="{ name: 'admin.content.contact' }">Contacts</router-link>
          <router-link class="collapse-item" :to="{ name: 'admin.content.faq' }">FAQ</router-link>
          <router-link class="collapse-item" :to="{ name: 'admin.content.documents' }">Documents</router-link>
        </div>
      </div>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div class="sidebar-heading">Requests</div>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.field-assistants.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Field Assistants</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.relationship-managers.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Relationship Managers</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.career-applications.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Career Applications</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.listing-referrals.index' }">
        <i class="fa fa-fw fa-building"></i>
        <span>Listing Referrals</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.painting-and-cleaning.index' }">
        <i class="fa fa-fw fa-list"></i>
        <span>Painting And Cleaning</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.movers.index' }">
        <i class="fa fa-fw fa-list"></i>
        <span>Movers</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.legal-and-surveying.index' }">
        <i class="fa fa-fw fa-list"></i>
        <span>Legal And Surveying</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.testimonials.index' }">
        <i class="fa fa-fw fa-building"></i>
        <span>Testimonials</span></router-link
      >
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div class="sidebar-heading">Settings</div>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.pricing.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Pricing</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.commissions.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Service Commissions</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.payments.index' }">
        <i class="fa fa-fw fa-tachometer"></i>
        <span>Payments</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.announcements.index' }">
        <i class="fa fa-fw fa-bullhorn"></i>
        <span>Announcements</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.search-terms.index' }">
        <i class="fa fa-fw fa-bullhorn"></i>
        <span>Search Terms</span></router-link
      >
    </li>

    <li class="nav-item">
      <router-link class="nav-link" :to="{ name: 'admin.data.index' }">
        <i class="fa fa-fw fa-bullhorn"></i>
        <span>Data & Analytics</span></router-link
      >
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
</template>

<script>
import initSidebar from '@/assets/admin/js/custom'

export default {
  mounted() {
    initSidebar()
  }
};
</script>