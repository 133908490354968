<template>
  <nav
    class="
      navbar navbar-expand navbar-light
      bg-white
      topbar
      mb-4
      static-top
      shadow
    "
  >
    <!-- Sidebar Toggle (Topbar) -->
    <button
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle me-3"
    >
      <i class="fa fa-bars"></i>
    </button>

    <!-- Go back -->
    <ul class="navbar-nav desktop-only" v-if="$route.name != 'admin.dashboard'">
      <li class="nav-item ms-3">
        <a href="#" class="" @click.prevent="$router.go(-1)"><i class="fa fa-arrow-left me-2"></i>Back</a>
      </li>
    </ul>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ms-auto" style="margin-right: 1rem;">
      <!-- Nav Item - Alerts -->
      <!-- <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'admin.notifications' }"
        >
          <i class="fa fa-bell fa-fw"></i>
          <span class="badge bg-danger badge-counter" v-if="1 == 0">3+</span>
        </router-link>
      </li> -->

      <!-- Nav Item - Messages -->
      <li class="nav-item mx-1">
        <router-link
          class="nav-link"
          :to="{ name: 'admin.messages.index' }"
        >
          <i class="fa fa-envelope fa-fw"></i>
          <!-- Counter - Messages -->
          <span class="badge bg-danger badge-counter" v-if="1 == 0">7</span>
        </router-link>
      </li>

      <div class="topbar-divider d-none d-sm-block"></div>

      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="userDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="me-2 d-none d-lg-inline text-gray-600 small"
            >{{ full_name }}</span
          >
          <img
            class="img-profile rounded-circle"
            src="/assets/admin/img/undraw_profile.svg"
          />
        </a>
        <!-- Dropdown - User Information -->
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="userDropdown"
        >
          <router-link class="dropdown-item" :to="{ name: 'admin.profile.index' }">
            <i class="fa fa-user fa-sm fa-fw me-2 text-gray-400"></i>
            My Profile
          </router-link>
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="logout"
          >
            <i class="fa fa-sign-out fa-sm fa-fw me-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      this.axios.post('/api/v1/logout').then(() => {
        this.$auth.removeToken()

        window.location.href = this.$router.resolve({ name: 'login' }).href
      })
    }
  },

  computed: {
    full_name() {
      return this.$store.getters.getAuthUser.name
    }
  }
};
</script>